import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Link } from 'gatsby'
const item1 = {image: "/img/course1.png"}
const item2 = {image: "/img/course2.png"}



export const ResearchPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    
    
    <section className="section section--gradient" style ={{padding:'0rem 1.5rem'}}>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section" style={{padding:'0.0rem'}}>
            <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/course_cover.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
              backgroundColor: '#f40',
              color: 'white',
              padding: '1rem',
            }}
          >
            Course
          </h1>
        </div>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={item1}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <h1 className="title" style = {{color: '#D64000'}}>CE 597: Machine Learning and Artificial Intelligence for Autonomous Vehicle Operations II

                      </h1>

                      <p>
                      <span class="subtitle is-size-5 has-text-weight-bold">Location:</span><br></br>
                      • Purdue University - West Lafayette<br></br>
                       <span class="subtitle is-size-5 has-text-weight-bold">Semesters:</span><br></br>
                       • Fall, 2020<br></br>
                       <span class="subtitle is-size-5 has-text-weight-bold">Instructor:</span><br></br>
                       • Dr. Sikai Chen
 
                      </p>
                      
                      </article>
                    </div>

                  </div>

                  
                </div>
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={item2}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <h1 className="title" style = {{color: '#D64000'}}>CE 597: Machine Learning and Artificial Intelligence for Autonomous Vehicle Operations

                      </h1>

                      <p>
                      <span class="subtitle is-size-5 has-text-weight-bold">Location:</span><br></br>
                      • Purdue University - West Lafayette<br></br>
                       <span class="subtitle is-size-5 has-text-weight-bold">Semesters:</span><br></br>
                       • Spring, 2020<br></br>
                       • Spring, 2021<br></br>
                       <span class="subtitle is-size-5 has-text-weight-bold">Instructor:</span><br></br>
                       • Dr. Sikai Chen
 
                      </p>
                      
                      </article>
                    </div>

                  </div>
                  
                  
                </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
  
}

ResearchPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ResearchPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ResearchPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

ResearchPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ResearchPage

export const researchPageQuery = graphql`
  query ResearchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
